/* countdown.css */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

#countdown * {
    font-family: 'Space Grotesk', sans-serif!important;
}

#countdown{
	width: 389px;
	text-align: center;
	border-radius: 5px;
	/* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6); */
	margin: 0 auto;
	padding: 10px 0;
    /* padding-top: 0; */
}

/* #countdown:before{
	content:"";
	width: 8px;
	height: 65px;
	background: #444;
	background-image: -webkit-linear-gradient(top, #555, #444, #444, #555); 
	background-image:    -moz-linear-gradient(top, #555, #444, #444, #555);
	background-image:     -ms-linear-gradient(top, #555, #444, #444, #555);
	background-image:      -o-linear-gradient(top, #555, #444, #444, #555);
	border: 1px solid #111;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	display: block;
	position: absolute;
	top: 48px; left: -10px;
}

#countdown:after{
	content:"";
	width: 8px;
	height: 65px;
	background: #442;
	background-image: -webkit-linear-gradient(top, #555, #444, #444, #555); 
	background-image:    -moz-linear-gradient(top, #555, #444, #444, #555);
	background-image:     -ms-linear-gradient(top, #555, #444, #444, #555);
	background-image:      -o-linear-gradient(top, #555, #444, #444, #555);
	border: 1px solid #111;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	display: block;
	position: absolute;
	top: 48px; right: -10px;
} */

#countdown #tiles{
	position: relative;
	z-index: 1;
}

#countdown #tiles > span{
    width: 80px;
    max-width: 80px;
    font: bold 30px 'Droid Sans', Arial, sans-serif;
    text-align: center;
    color: #ddd;
    background-color: #222;
    background-image: -o-linear-gradient(top, #bbb, #eee);
    border-radius: 3px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
    margin: 0 7px;
    padding: 18px 0;
    display: inline-block;
    position: relative;
}

#countdown #tiles > span:before{
	content:"";
	width: 100%;
	height: 13px;
	background: #111;
	display: block;
	padding: 0 3px;
	position: absolute;
	top: 41%; left: -3px;
	z-index: -1;
}

#countdown #tiles > span:after{
	content:"";
	width: 100%;
	height: 1px;
	background: #eeeeee63;
	border-top: 1px solid #333;
	display: block;
	position: absolute;
	top: 48%; left: 0;
}
#header{
	width: 100%;
	font: bold 40px 'Droid Sans', Arial, sans-serif;
	color: #fff;
	/* text-shadow: 1px 1px 0px #000; */
	text-align: center;
	text-transform: uppercase;
	display: inline-block;
    margin-bottom: 12px;
}
#countdown .labels{
	width: 100%;
	height: 25px;
	text-align: center;
    margin-top: 10px;
    
}

#countdown .labels li{
	width: 95px;
	font: bold 15px 'Droid Sans', Arial, sans-serif;
	color: #fff;
	text-shadow: 1px 1px 0px #000;
	text-align: center;
	text-transform: uppercase;
	display: inline-block;
}