@import url('https://fonts.cdnfonts.com/css/spicy-rice');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.join_mission_showonmb {
  display: none;
}

a.apply_button.aftershowondesk {
  margin-top: 30px !important;
}

.becomeapplybtn {
  margin-top: 30px !important;
}

.banderpar {
  text-align: left !important;
}

.beforetext {
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
  white-space: nowrap;
}

.aftertext {
  margin: 0px;
}

.afterh2 {
  margin-top: 110px !important;
}

.sldercommenter .slick-list {
  width: calc(100% - 10%);
}

.commnter_foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.commnter_foot .foot33 {
  width: 100%;
  max-width: 31%;
  gap: 20px;
}

.foot332 {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 31%;
}

.foot333 {
  width: 100%;
  max-width: 31%;
  justify-content: end;
  display: flex;
  align-items: center;
}

.commnter_foot ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 0px;
}

.commnter_foot ul>li>a {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #fff;
  text-decoration: none;
}

.commntercopyright {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #fff;
}

.commnterfootimg {
  width: 100%;
  height: auto;
  max-width: 165px;
}

.footcontent a {
  cursor: pointer;
}

.footcontent {
  text-align: center;
  position: relative;
  border-bottom: 1px solid #FFFFFF80;
  padding-bottom: 100px;
}

.footcontent .imgcerclefoot {
  position: absolute;
  right: 90px;
  height: auto;
  width: 100%;
  max-width: 100px;
  top: 138px;
}

.footcontent .imgcerclefoot2 {
  position: absolute;
  left: 90px;
  height: auto;
  width: 100%;
  max-width: 100px;
}

.footheading {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -1px;
  text-align: center;
  color: #fff;

}

.footcontent p {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #fff;

}

.tickdive {
  display: flex;
  gap: 15px;
  align-items: center;
}

.tickdive p {

  text-align: left !important;
}

.tickouterdiv {
  margin-top: 40px;
}

.slides_slick.slides_slick2 {
  width: 100%;
  display: inline-block;
  max-width: 1150px;
  margin: auto;
  display: flex !important;
  gap: 50px;
  align-items: center;
  margin-top: 50px;
}

.slides_slick {
  display: flex !important;
  gap: 50px;
  align-items: center;
}

.result_slider_verti1 .slick-vertical .slick-slide {
  width: 500px;
}

.slides_slick p {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  max-width: 540px;

}

.star_slide {
  display: flex !important;
  gap: 0px;
  margin-top: 20px;
}

.slidename {
  font-family: Inter;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: left !important;
  margin-bottom: 0px;

}

.slidewho {
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: left !important;
  color: #666666 !important;
  margin-top: 10px;

}


.apply_button {
  position: relative;
}

.shapebtn1 {
  position: absolute;
  z-index: 0;
  top: -8px;
  left: -17px;
  width: 13px;
  height: 14px;
}

.shapebtn2 {
  position: absolute;
  z-index: 0;
  bottom: -28px;
  right: -25px;
  width: 25px;
  height: 20px;
}

.shapebtn3 {
  position: absolute;
  z-index: 0;
  bottom: 12px;
  right: -185px;
  width: 78px;
  height: 28px;
}

.shapebtn4 {
  position: absolute;
  z-index: 0;
  top: 82px;
  right: -175px;
  width: 172px;
  height: 37px;
}

.shapebtn5 {
  position: absolute;
  z-index: 0;
  top: 82px;
  right: -185px !important;
  width: 172px;
  height: 37px;
}

.result_slider_verti .slick-prev {
  left: 570px;
  top: 160px;
}

.result_slider_verti {
  /* height: 417px; */
  padding-right: 50px !important;

}

.result_slider_verti1 {
  /* height: 417px; */
  /* padding-right: 50px; */

}

.result_slider_verti2 .slick-track {
  width: 100%;
  display: flex;

}

/* .result_slider_verti2 .slick-list {
  width: calc(100% - 60px) !important;
} */
.result_slider_verti1 .slick-next {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background: #0050B2 !important;
  padding: 10px;
  -webkit-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75) !important;
  right: 0px;
}

.result_slider_verti1 .join_mission {
  width: 100% !important;
}

.result_slider_verti1 .slick-prev {
  left: 603px;
  top: 140px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background: #f6f6f6 !important;
  padding: 10px;
  -webkit-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75);
  border-radius: 50px;

}

.result_slider_verti2 .slick-next {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background: #0050B2 !important;
  padding: 10px;
  -webkit-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75) !important;
  right: 3px;
}

.result_slider_verti2 .join_mission {
  width: 100% !important;
}

.result_slider_verti2 .slick-prev {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  background: #f6f6f6 !important;
  padding: 10px;
  -webkit-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 35px 96px -24px rgba(0, 0, 0, 0.75);
  border-radius: 50px;
  right: 0;

}

.slick-prev:before,
.slick-next:before {
  font-size: 0px;
}

.result_slider_verti .slick-dots {
  text-align: right;
  right: 0px !important;
  top: 179px;
  border: 0;
  width: 30px;
  background-attachment: red;
}

/* .result_slider_verti img.slick-arrow.slick-prev {
  left: 551px !important;
  top: 140px;
  background: red;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  padding: 10px;
  right: 0px !important;
} */
/* .result_slider_verti .slick-prev, .slick-next {
  top: 54% !important;
} */
.result_slider_verti .slick-prev,
.slick-next {
  z-index: 1;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: gray;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  cursor: pointer;
}


.apply_button {
  width: 173px;
  height: 56px;
  border-radius: 8px;
  /* opacity: 0px; */
  background: #0050B2;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1599999964237213px;
  color: #fff;
  border: none;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.footcontent .apply_button {
  margin: auto;
  margin-top: 40px;

}

.imagebefore {
  position: relative;
}

/* .imagebefore::before{
  content: '';
  background: url(arrowbefore1.png);
  position:absolute;
  top:0px;
  left:0px;
} */



/* .main_con_thanks {
  position: fixed;
}
.sectionpad {} */
.arrowdown {
  width: 30px;
}

.checkoutlable {
  display: flex;
  justify-content: start;
  /* align-items: center; */
  gap: 10px;
}

.checkoutlable {
  text-align: left !important;
  margin-top: 10px;
  margin-bottom: 15px;
}

.thankubtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: solid 2px #0050b2;
  border-radius: 50px;
  /* padding: 5px 10px 2px 10px; */
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  color: #0050b2;
  font-weight: bold;
}

.thankubtn img {
  padding: 5px 5px 1px 10px;
}

/* .thankubtn .geturbtn {
  background: #ff2400;
  color: #fff;
  padding: 23px;
  border-radius: 50px;
} */
.thankubtn .geturbtn2 {
  background: #ff2400;
  color: #fff;
  padding: 23px;
  border-radius: 50px;
  border: 4px solid rgba(255, 36, 0, 0.3);
}

.thankubtn .geturbtn2:hover {
  background: #0050b2;
  color: #fff;
  padding: 23px;
  border-radius: 50px;
  border: 4px solid rgb(0 80 178 / 0%);
}

.Thankulogo {
  margin-bottom: 26px;
}

.staradded {
  font-size: 18px;
  color: #0050b2;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin: 0px;
}

.staradded span {
  margin-right: 5px;
}

.thankubtn {

  box-shadow: inset 0px 0px 0px 2px #0050b2;
}

.chorme_today {
  font-size: 30px;
  color: #0050b2;
  font-weight: bold;
  margin: 0px;
}

.boxicon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-size: 22.5px;
  color: #0050b2;
  font-weight: 500;
}

.sectionbox2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
  margin-top: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div,
img,
h2,
h1,
h3,
h4,
h5,
h6,
a {
  transition: .4s;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  /* max-width: 686px; */
}

.slider_right_sec .swiper-button-next,
.swiper-button-prev {
  color: #007AFF;
}

.slider_right_sec .swiper-pagination-bullet-active {
  background: #fff;
}

.slider_right_sec .swiper-pagination-bullet {
  background: #fff;
}

/* .aside2 .sc-dauhQT.bccQxm {
  max-width: 690px;
} */
.App {
  overflow: hidden;
}

.swiper-button-prev {
  left: 2px !important;
}

.swiper-button-next {
  right: 2px !important;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 16px;
}

/* Public pages template */

.public-pages {
  min-height: 100vh;
  display: flex;
  flex-flow: column-reverse;
}

.public-pages aside {
  flex: 1;
  background-color: #0050B2;
  display: flex;
  align-items: center;
  flex-flow: column;
  color: #ffffff;
  /* padding: 1rem; */
  /* justify-content: center; */
}

.public-pages .aside2 {
  /* padding: 0px 30px 0px 50px; */
}

.header>img:hover {
  cursor: pointer;
}

.mobile>img:hover {
  cursor: pointer;
}

video {
  margin: 0 !important;
}

body .nsm7Bb-HzV7m-LgbsSe.MFS4be-v3pZbf-Ia7Qfc {
  max-width: 70% !important;
  margin: auto;
}

.swiper-slide.swiper-slide-next,
.swiper-slide.swiper-slide-active {
  margin-right: 0px !IMPORTANT;
}
.thankyou-page-anchor span.navigation-link.login {
  color: white;
  text-decoration: none;
  text-transform: UPPERCASE;
}
.thankyou-page-anchor span.navigation-link.login:hover  {
  color: #0050b2;
}

@media only screen and (min-width:768px) {
  .public-pages {
    flex-flow: row;
  }
}

.public-pages>aside>div {
  display: flex;
  align-items: center;
  flex-flow: column;
  height: 100%;
  padding-top: 0;
}

.public-pages .aside-image {
  animation: image-fade-up 1.25s;

}

.public-pages .aside-header {
  width: 100%;
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  /* animation: image-fade-up-main 1.75s; */
}

.public-pages .aside-paragraph {
  width: 374px;
  font-weight: 500;
  font-size: 16px;
  line-height: 164.52%;
  text-align: center;
  margin-block-start: 0;
  /* animation: image-fade-up-main 2.25s; */
}

.public-pages .aside-ul {
  list-style-type: "";
  padding-inline-start: 0;
}

.public-pages .aside-list {
  /* list-style-image: url('./assets/list-icon.svg'); */
  list-style-image: none;
  /* font-size: 17px; */
  /* animation: image-fade-up-main .75s; */
}

.public-pages .aside-list img {
  padding-right: 0px;
  margin-bottom: -4px;
}

.public-pages .content-section {
  flex: 1;
  display: flex;
  align-items: center;
  flex-flow: column;
}

.aftershowmb {
  display: none;
}

@media only screen and (min-width:768px) {

  .public-pages .content-section {
    padding: 1rem 1rem 0rem;
  }

  .public-pages .content-section>img+div {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {

  .slides_slick p {
    font-size: 18px;
    line-height: 28px;
  }

  h2.afterh2 {
    margin-top: 73px !important;
  }

  .aftershowmb {
    display: block;
  }

  .beforetext {
    font-size: 12px;
  }

  .startwarpdiv {
    margin-top: 10px;
  }

  .missiondiv {
    height: auto;
  }

  .par3 {
    text-align: center !important;
  }

  .public-pages .content-image {
    max-width: 200px;
    margin-top: 0;
    animation: image-fade-up-main .75s;
  }

  .faq_pricing_sec .jioJLP {
    top: 0px;
    right: 0px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }

  .SliderMain .slick-prev,
  .SliderMain .slick-next {
    width: 30px;
    height: 30px;
    z-index: 9;
  }
}

/** Platform pages layout */

.platform-pages {
  min-height: 100vh;

}

.platform-pages .header {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background-color: var(--white);
  box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.15);
  padding: 0 5%;
  animation: fade-down .8s;
}

.platform-pages .main-logo {
  margin: 1rem 0;
  width: auto;
  max-height: 50px;


}

.platform-pages nav {
  display: flex;
  margin: 1rem 0;
  align-items: center;
}

.platform-pages nav>a {
  text-decoration: none;
  transition: .4s;
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 500;
  /* font-size: 18px; */
  font-size: 16px;
  line-height: 25px;
  box-sizing: border-box;
}

.platform-pages .aside-ul {
  list-style-type: "";
  padding-inline-start: 0
}

.platform-pages .aside-list {
  /* list-style-image: url('./assets/list-icon.svg'); */
  list-style-image: none;
  font-size: 24px;
  line-height: 134.52%;
  margin-bottom: .5rem;
  /* font-weight: bolder; */
}

.platform-pages .aside-list img {
  max-width: 20px;
  height: auto;
  padding-right: 0px;
  margin-bottom: -4px;
  margin-right: 8px;
}

.platform-pages nav>a:nth-last-child(2) {
  margin-right: 3rem;
}

.platform-pages nav>a.active>span {
  color: #0050B2;
}

.platform-pages .navigation-link {
  margin-left: 1.5rem;
  color: #000000;
  transition: .4s;
}

.platform-pages .navigation-link:hover {
  color: #0050B2;
  transition: .4s;
}

.platform-pages .small-headline-span {
  color: #0A66C2;
}

.mail-img {
  height: 1rem;
  margin-bottom: -5px;
  margin-right: 5px;
}

.platform-pages footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-grey);
  padding: 0 5%;
  animation: fade-down .8s;
}

.platform-pages footer nav>a {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 25px;
  color: var(--white);
  text-decoration: none;
  margin-left: 2rem;
}

.platform-pages .mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 1rem;
}

.platform-pages .mobile img {
  height: 50px;
  width: auto;
  z-index: 20;
}

#menuToggle {
  position: relative;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #4d4d4d;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle span:nth-last-child(1) {
  margin-top: -26px !important;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked~ul {
  transform: none;
}

.platform-pages .mobile nav {
  display: flex;
  flex-flow: column;
  position: absolute;
  width: 100%;
  background-color: #fff;
  top: -100%;
  z-index: 40;
  left: 0;
  text-align: center;
  transition: 1s;
}

.platform-pages .mobile nav.show {
  top: 60px;
  transition: 1s;
}

.platform-pages .mobile nav>a {
  padding: 1rem;
  width: 100%;
}

.platform-pages .mobile nav>a .navigation-link {
  margin-left: 0;
}

.platform-pages .mobile nav figure {
  display: none;
}

.platform-pages footer>img {
  height: 48px;
}

@media only screen and (min-width:1024) and (min-width:1800px) {
  .platform-pages .navigation-link {
    margin-left: 1.15rem;
  }
}





@media only screen and (max-width:1600px) {

  .platform-pages .header {
    padding: 0 15px;
    position: relative;
    z-index: 99;
  }

  body .platform-pages .navigation-link {
    margin-left: 10px;
    font-size: 16px;
  }

}

@media only screen and (max-width:1299px) {
  .slides_slick.slides_slick2 {
    width: 92%;
  }

  .platform-pages .header {
    display: none;
    height: 50px;
  }

  .platform-pages .main-logo {
    width: 102px;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* .platform-pages nav {
    display: none;
  } */

  .platform-pages .mobile nav>a:nth-last-child(2) {
    margin-right: 0;
  }

  body .platform-pages .navigation-link {
    font-size: 18px;
  }

  body .footerLink {
    display: flex;
  }

  body .footerLogo {
    height: 48px;
    width: auto;
  }


}

@media only screen and (max-width:1061px) {
  .cusresul2 {
    width: 381px;
  }
}

@media only screen and (max-width:1024px) {

  .join_mission_showondesk {
    display: none;
  }

  .join_mission_showonmb {
    display: block;
    display: flex;
    justify-content: center;
    max-width: 70% !important;
    margin: auto;
  }

  .cusresul2 {
    width: 100%;
  }
}

@media only screen and (max-width:991px) {
  .banderpar {
    text-align: center !important;
  }

  body .footerLink {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 45px;
  }

  body .footer_head {
    flex-direction: column;
    height: 100%;
  }

  body .footer_head>img {
    margin-top: 40px;
  }

  body .footerLink a {
    margin-left: 0px !IMPORTANT;
    margin-right: 0px !IMPORTANT;
  }
}

@media only screen and (max-width: 768px) {
  .join_mission_showonmb {
    max-width: 100% !important;
  }

  .public-pages footer {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-flow: column-reverse;
  }

  .public-pages footer nav {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .public-pages footer nav>a {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .platform-pages footer {
    min-height: 100px;
    height: auto;
  }

  .platform-pages footer nav {
    display: flex;
    flex-flow: column;
  }

  .platform-pages footer nav a {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  body .footerLink {

    margin-bottom: 45px;
  }

  body .footer_head {
    flex-direction: column;
    height: 100%;
  }

  body .footer_head>img {
    margin-top: 40px;
    display: block !important;
  }
}

@media only screen and (min-width: 1300px) {
  .platform-pages .mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {

  button.apply_button.apply_joinbtn {
    margin: auto !important;
  }

  .footcontent .imgcerclefoot {
    right: 0px !important;
  }

  .footcontent .imgcerclefoot2 {
    left: 0;
    width: 70px;
  }

  .platform-pages footer nav {
    margin-bottom: 0;
  }

  .platform-pages footer>img {
    display: none;
  }

  .platform-pages nav>a:nth-last-child(2) {
    margin-right: 0;
  }

  .platform-pages footer nav a {
    margin-left: 0;
    margin-bottom: 1rem;
  }

}

/* Sales pages */
.sales {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
}

.sales nav>a .navigation-link {
  text-transform: uppercase;
}

.sales nav>a .navigation-link.login {
  color: #fff;
}

.sales nav>a .navigation-link.login:hover {
  color: #0050B2;
}

.platform-pages.sales nav>a:nth-last-child(2) {
  margin-right: 0 !important;
}

.platform-pages .navigation-link {
  /* margin-left: 1.5rem; */
  margin-left: 1.4rem;
  /* margin-left: 2rem; */
}

.sales footer img {
  height: 50px;
  width: auto;
}

.sales footer nav span {
  color: #fff;
  margin-left: 25px;
}

.sales footer nav {
  font-size: 19px;
}

.sales footer nav a {
  font-size: 19px;
  white-space: nowrap;
}


.main-container.terms,
.main-container.privacy {
  flex-flow: column;
  text-align: left;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
}

.terms h1,
.privacy h1 {
  width: 100%;
  max-width: 100%;
  color: #0050B2;
  font-size: 40px;
  text-align: center;
}


.terms h2,
.privacy h2 {
  margin-block-start: 1.5rem;
  margin-block-end: .25rem;
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #0050B2;
  animation: slide-from-bottom-slow 1.2s;
}

.terms p,
.privacy p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2E2E2E;
  margin-block-start: .5em;
  margin-block-end: .5em;
  animation: slide-from-bottom-slow 1.4s;
}

.terms a,
.privacy a {
  text-decoration: none;
  color: #0050B2;
}

.responster-popup {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 1;
  padding: 10px;
}

.responster-close-survey-btn {
  background: none;
  cursor: pointer;
  outline: none;
  margin-left: auto;
  display: block;
  border: none;
  margin-bottom: 25px;
}

.responster-popup-overlay {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
  border: none;
}

.react-time-picker__wrapper {
  border: none;
}

input.react-time-picker__inputGroup__input {
  width: 30px !important;
}

.react-time-picker__inputGroup__amPm option {
  background-color: #353535;
}

.react-time-input-picker {
  display: flex;
  justify-content: center;
}

.inputWrapper input {
  background: transparent;
  border: none;
  width: 24px;
  color: #fff;
  text-align: center;
}

/* .inputWrapper {
  max-height: 350px;
  overflow-y: scroll;
} */
.inputWrapper span.arrow-down {
  cursor: pointer;
}

.inputWrapper span.arrow-down svg g path {
  fill: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputWrapper__dropdown {
  position: absolute;
  background: white;
  color: #363636;
  border-radius: 5px;
  max-height: 350px;
  overflow-y: scroll;
}

.inputWrapper__dropdown span {
  cursor: pointer;
  display: block;
  padding: 8px;
}

.inputWrapper__dropdown span:hover {
  background: rgb(54 54 54 / 0.1);
}

.inputWrapper__dropdown span.is-active {
  background: rgb(54 54 54 / 0.1);
}

.public-pages .aside2 {
  /* padding: 0px 30px 0px 50px; */
  box-sizing: border-box;
}

.SliderMain .slick-prev,
.SliderMain .slick-next {
  width: 36px;
  height: 36px;
  z-index: 9;
}

.SliderMain .slick-list {

  margin: auto;

}

.SliderMain .slick-prev {
  left: 0px;
  transform: translate(0, 0%);
}

.SliderMain .slick-next {
  right: 0px;
}

.SliderMain .slick-dots li {
  margin: 0 2px;
}

.SliderMain .slick-dots li button:before {
  font-size: 18px;
}

.SliderMain .slick-dots {
  bottom: -67px;
}

.SliderMain .slick-slide>div {
  text-align: center;
}

.SliderMain .slick-slide img {
  display: block;
  box-shadow: 0px 4px 12.7px 0px #12121217;
  border-radius: 0 0 10px 10px;
  max-width: 574px;
  min-height: 278px;
  background: white;
}

.SliderMain .slick-slide {
  padding: 10px 0px;
  box-sizing: border-box;
}

.SliderMain .slick-prev,
.SliderMain .slick-next {
  position: static;
}

.SliderMain {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {

  .main-container.terms,
  .main-container.privacy {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1499px) {
  .aside2 .sc-dauhQT.bccQxm {
    /* max-width: 610px; */
  }
}

@media only screen and (max-width: 1300px) {
  .aside2 .sc-dauhQT.bccQxm {
    /* max-width: 450px; */
  }
}

@media only screen and (max-width:1299px) {
  .result_slider_verti2 .slick-next {
    right: -1px;
    width: 20px;
    height: 20px;
  }

  .result_slider_verti2 .slick-prev {
    left: 2px;
    width: 20px;
    height: 20px;
  }

  .slides_slick.slides_slick2 {
    max-width: 950px;
    margin: auto;
  }

  .toprightimages.hCofWm {
    min-height: 100%;
  }

  .screenimgs img {
    width: 150px;
  }

  .sectionbox2 {
    gap: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  a.apply_button.aftershowondesk {
    margin: auto;
    margin-top: 30px !important;
  }

  button.apply_button.apply_joinbtn {
    margin: unset;
  }

  .result_slider_verti2 .slick-next {
    width: 15px;
    height: 15px;
  }

  .result_slider_verti2 .slick-prev {
    width: 15px;
    height: 15px;
  }

  .result_slider_verti2 .slick-list {
    width: calc(100% - 64px) !important;
    margin: auto;
  }

  .sldercommenter .slick-list {
    width: calc(100% - 14%);
    margin: auto;
  }

  .slides_slick.slides_slick2 {
    gap: 0;
  }

  .result_slider_verti2 .slick-next {
    top: 310px;
  }

  .result_slider_verti2 .slick-prev {
    top: 310px;
  }

  .join_mission2 {
    width: 100%;
    height: auto;
  }

  .slides_slick.slides_slick2 {
    max-width: 930px;
    margin: auto;
    margin-top: 20px;
  }

  .slides_slick {
    flex-direction: column;
  }

  .result_slider_verti1 .slick-vertical .slick-slide {
    width: 100%;
  }

  .cusresultdiv {
    margin-bottom: 30px;
  }

  .result_slider_verti1 .slick-prev {
    left: -21px;
    width: 15px;
    height: 15px;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }

  .result_slider_verti,
  .result_slider_verti1 {
    padding-right: 0 !important;
  }

  .result_slider_verti1 .slick-next {
    right: -21px;
    width: 15px;
    height: 15px;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }

  .result_slider_verti1 .join_mission {
    max-width: 80%;
    width: 100%;
    margin: auto;
    height: 400px;
  }

  button.apply_button {
    margin: auto;
    text-align: center;
    /* width: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  /* .shapebtn5 {
    display: none;
}
.shapebtn4 {
  display: none;
} */
  /* .slider_right_sec .swiper-button-next, .swiper-button-prev {
    color: #0050b2;
} */
  .faq_pricing_sec .jioJLP {
    width: 40px;
    height: 40px;
  }

  .faq_pricing_sec .eRBJYz {
    width: 50px;
    height: 50px;
  }

  .aside2 .sc-dauhQT.bccQxm {
    max-width: 88%;
  }

  /* .public-pages .aside2 {
    padding: 15px 0px 0px 0px;
  } */

  .main-container.terms,
  .main-container.privacy {
    flex-flow: column;
    text-align: left;
    margin: 0 1rem;
    align-items: start;
  }

  .thanksmaintitle {
    font-size: 48px;
    line-height: 58.24px;
  }

  .sectionbox2 {
    gap: 90px;
    flex-direction: column;
    gap: 0px !important;
  }

  .boxicon {
    justify-content: start;
    width: 320px;
  }
}

@media only screen and (max-width: 768px) {
  .faq_pricing_sec .jioJLP {
    width: 30px !important;
    height: 30px !important;
    font-size: 30px !important;
  }

  .faq_pricing_sec .eRBJYz {
    width: 40px !important;
    height: 40px !important;
  }

  .faq_pricing_sec .hefhtO {
    padding: 10px 15px 15px 15px;
  }

  .screenimgs img {
    width: 90px;
  }

  .thankubtn {
    font-size: 14px;
  }

  .thanksmaintitle {
    font-size: 38px;
    line-height: 48.24px;
  }

  .chorme_today {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sectionpad {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .thankubtn {
    flex-direction: column;
    padding: 10px;
  }

  .banner_img1 {
    max-width: 100%;
    height: auto;
  }

  .shapebtn3 {
    right: -85px;
  }
}

@media only screen and (max-width: 420px) {

  .result_slider_verti2 .slick-next {
    top: 230px;
  }

  .result_slider_verti2 .slick-prev {
    top: 230px;
    left: -5px;
  }

  .thanksmaintitle {
    font-size: 27px;
    line-height: 34px;
  }

  .Thankulogo {
    margin-bottom: 30px;
    width: 220px;
  }

  .boxicon {
    gap: 17px;
    width: 300px;
    font-size: 20.5px;
  }

  .checkoutlable {
    gap: 8px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 381px) {
  .checkform {
    font-size: 15px;
  }



  .bbjFKY input {
    width: 15px;
    height: 15px;
  }
}

@keyframes scaleImage {
  0% {
    transform: scale(0);
    /* Original size */
  }

  100% {
    transform: scale(1);
    /* Scaled to 150% of the original size */
  }
}

.congrate-img {
  animation: scaleImage 1s ease-in-out;
}

:root {
  --light-blue: #58b6e6;
  --dark-blue: #1953a4;
  --animation-duration: 3s;
  /* recommended range 2s-4s */
  --logo-width: 4rem;
  /* recommended 3rem - 4rem */
  --text-color: white;
  --text-background: #1953a4;
  --text-size: 0.8rem;
  --frame-width: 16rem;
  --move-left-limit: -6rem;
  --move-right-limit: 6rem;
  --text-transform: uppercase;
  /* available values: uppercase, lowercase, capitalize, none */
}

/* img {
  width: var(--logo-width);
} */

.container {
  min-width: var(--frame-width);
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
  animation: move var(--animation-duration) ease-in-out infinite forwards;
}

@keyframes move {

  0%,
  100% {
    transform: translateX(var(--move-left-limit));
  }

  40%,
  70% {
    transform: translateX(var(--move-right-limit));
  }
}

.loading-letters {
  font-size: var(--text-size);
  font-weight: bold;
  font-family: "Space Grotesk", monospace;
  text-align: center;
}

.loading-letters span {
  text-transform: var(--text-transform);
  background-color: var(--text-background);
  padding-inline: 0.25rem;
  margin: 0;
  display: inline-block;
  color: var(--text-color);
  animation: drop var(--animation-duration) infinite ease-in-out;
}

.loading-letters span:nth-child(1) {
  animation-delay: 100ms;
}

.loading-letters span:nth-child(2) {
  animation-delay: 200ms;
}

.loading-letters span:nth-child(3) {
  animation-delay: 300ms;
}

.loading-letters span:nth-child(4) {
  animation-delay: 400ms;
}

.loading-letters span:nth-child(5) {
  animation-delay: 500ms;
}

.loading-letters span:nth-child(6) {
  animation-delay: 600ms;
}

.loading-letters span:nth-child(7) {
  animation-delay: 700ms;
}

.loading-letters span:nth-child(8) {
  animation-delay: 800ms;
}

.loading-letters span:nth-child(9) {
  animation-delay: 900ms;
}

.loading-letters span:nth-child(10) {
  animation-delay: 1000ms;
}

@keyframes drop {

  0%,
  100% {
    transform: translateY(-2rem);
    opacity: 0;
  }

  10%,
  50% {
    transform: translateY(0);
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  90% {
    transform: translateY(0);
  }
}

.toggle-button {
  display: none;
  /* Initially hide the toggle button */
}

.toggled-content {
  display: block;
  /* Initially hide the toggled content */
}

.faqHomePage .sc-dxUMQK.jYdOKB {
  justify-content: center;
}

.faqHomePageWrapper .faqHomePageIcon>div {
  justify-content: center;

}

@media only screen and (max-width: 1024px) {

  .toggle-button {
    display: block;
    margin-top: 10px;
    BACKGROUND: white;
    HEIGHT: MAX-CONTENT;
    POSITION: ABSOLUTE;
    Z-INDEX: 9;
    WIDTH: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #000;
    left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: 1px 1px 9px 1px #fff;
  }

  .toggled-content {
    display: block;
    /* Show the toggled content on screens with a maximum width of 1024px */
  }
}

.slick-slide {
  padding: 0 5px;
  box-sizing: border-box;
}

.slick-slider div {
  transition: none;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #0050b261;
}

.slick-dots li.slick-active button:before {
  color: #0050B2;
}

span.clicked {
  border: 2px solid red !important;
  border-radius: 50% !important;
}

.Togglewrapper label {
  width: 100px !important;
  height: 36px !important;

}

.Togglewrapper span::before {
  /* transform: translateX(2px) !important; */
  height: 29px !important;
  width: 66px !important;
  content: "Disabled" !important;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px !important;
}

.Togglewrapper input:checked+span::before {
  transform: translateX(26px);
  content: "Enabled" !important;
}

.voicetitle {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

@media only screen and (max-width:1023px) {
  .SliderMain .slick-track {
    display: flex;
    /* flex-direction: column; */
    gap: 25px;
  }

  .slick-track>div {
    width: 100% !important;
  }

  .slider_right_sec .swiper-button-next,
  .swiper-button-prev {
    color: #0050b2;
  }

  .SliderMain .slick-slide img {
    min-height: 206px;
  }

  .SliderMain .slick-track {
    gap: 0;
  }

}

@media only screen and (max-width:991px) {
  .hewfhq {
    max-width: 500px !important;
    margin: auto;
    /* background: red; */
  }

  .shapebtn4 {
    right: -102px !important;
    width: auto !important;
    height: auto;
    transform: rotate(90deg);
    top: 300px;
  }

  .shapebtn5 {
    left: -93px !important;
    width: auto !important;
    height: auto;
    transform: rotate(90deg);
    top: 320px;
  }

  /* .hewfhq {
    width: 100%;
    max-width: 100% !important;
} */
  .becomeapplybtn {
    margin: auto;
    margin-top: 30px;
  }

  .beforetext {
    white-space: unset;
  }

  .SliderMain .slick-slide img {
    min-height: 187px;
  }
}

@media only screen and (max-width: 767px) {

  .sldercommenter .slick-list {
    width: calc(100% - 4%);
  }

  .result_slider_verti2 .slick-next {
    width: 10px;
    height: 10px;
  }

  .result_slider_verti2 .slick-prev {
    width: 10px;
    height: 10px;
  }

  .result_slider_verti1 .slick-prev {
    width: 10px;
    height: 10px;
  }

  .result_slider_verti1 .slick-next {
    width: 10px;
    height: 10px;
  }

  h2.afterh2 {
    margin-top: 0px !important;
  }

  h2.afterh2 {
    margin-top: 0px;
  }

  .sales footer>img {
    display: none;
  }

  .sales footer nav span {
    margin-left: 0;
  }

  .SliderMain .slick-dots {
    bottom: -40px;
  }

  .SliderMain .slick-slide {
    padding: 10px 10px;
    box-sizing: border-box;
  }

  .SliderMain {
    gap: 5px;
  }

  .SliderMain .slick-list {
    margin: auto;
    box-shadow: 0px 4px 12.7px 0px #1212123d;
    border-radius: 10px;
  }

  .SliderMain .slick-slide img {
    min-height: auto;
  }

  body .aftershowondesk {
    display: none;
  }

  body .footcontent .imgcerclefoot {
    top: 224px !important;
    width: 70px;
  }

}


@media only screen and (max-width:1299px) {
  .shapebtn4 {
    right: -145px;
    width: 152px;
  }

  .shapebtn5 {
    right: -135px !important;
    width: 152px;
  }
}

@media only screen and (max-width: 1199px) {
  .slides_slick.slides_slick2 {
    width: 90% !important;
  }

  /* .commnter_foot {
    flex-direction: column;
    gap: 20px;
} */
  .join_mission2 {
    width: 100%;
    height: auto;
  }

  .shapebtn5 {
    right: -65px !important;
    width: 102px;
  }

  .shapebtn4 {
    right: -74px;
    width: 102px;
  }

  .join_mission {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  footer div:nth-child(1) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  footer div:nth-child(1)>p {
    width: 100%;
    text-align: center;
  }

  .eMpxxw {
    justify-content: center !important;
  }

  .sales footer nav {
    font-size: 19px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width:1087px) {
  .commnter_foot {
    flex-direction: column;
  }

  .commnter_foot .foot33 {
    max-width: 100%;
    justify-content: center;
    display: flex;
  }

  .foot332 {
    max-width: 100%;
  }

  .foot333 {
    max-width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 1025px) {
  .result_slider_verti1 .slick-prev {
    left: 543px;
  }
}

@media screen and (max-width: 1024px) {
  .result_slider_verti1 .slick-prev {
    left: -18px;
  }
}

@media screen and (max-width: 768px) {
  body .par3 {
    text-align: center !important;
  }

  a.apply_button.apply_joinbtn {
    margin: auto;
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .slides_slick.slides_slick2 {
    width: 97% !important;
  }

  .hewfhq {
    max-width: 360px !important;
  }
}

@media screen and (max-width: 445px) {
  .hewfhq {
    max-width: 283px !important;
  }
}

@media screen and (max-width: 381px) {
  .slides_slick.slides_slick2 {
    max-width: 320px !important;
    margin: auto;
  }

  .hewfhq {
    max-width: 239px !important;
  }

}

@media screen and (max-width: 370px) {
  .shapebtn3 {
    right: -67px;
    width: 50px;
  }

  body .footcontent .imgcerclefoot {
    top: 261px !important;
  }

  .commnter_foot ul>li>a {
    font-size: 12px;
  }

  .aftertext {
    margin: 0px;
    line-height: 7px;
  }

  .beforetext {
    line-height: 2;
  }

  .tickdive p {
    font-size: 18px !important;
    margin: 5px 0px !important;
  }
}

@media screen and (max-width: 320px) {
  .hewfhq {
    max-width: 243px !important;
  }
}