.spm-copy-btn {
    background: url('chrome-extension://__MSG_@@extension_id__/img/logo.png');
    display: inline-flex;
    border: none;
    cursor: pointer;
    width: 42px;
    min-width: 42px;
    height: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.spm-generate-btn {
    background: url('chrome-extension://__MSG_@@extension_id__/img/social-icon.png');
    display: inline-flex;
    border: none;
    cursor: pointer;
    width: 30px;
    min-width: 30px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top:3px;
}

.loader-spinner {
    width: 25px;
    height: 25px;
    border: 4px solid #000000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}


@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Popover styles */
.webui-popover {
    margin-left: 53px !important;
    font-size: 16px;
    line-height: 24px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
}

.webui-popover * {
    box-sizing: border-box;
}


.webui-popover.sales-page {
    margin-left: calc(100% - 54.4rem) !important;
    transform: translateX(-102%);
}

.webui-popover.sales-page * {
    box-sizing: border-box;
}

.webui-popover-inner .close {
    font-size: 35px;
    font-weight: 100;
}

.webui-popover-title {
    padding: 0
}

#spm-popover-template {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
}

#spm-popover-template .spm-pop-header {
    width: 100%;
    display: inline-block;
    /* border-bottom: 1px solid #cdc8c8; */
    padding-bottom: 0px;
}

#spm-popover-template .spm-pop-mood {
    width: 100%;
    float: left;
    margin-bottom: 5px;
}

#spm-popover-template .spm-pop-keywords {
    float: left;
    width: 100%;
}

#spm-popover-template .spm-pop-keywords>div
{
    display: flex;
}

#spm-popover-template .clearfix {
    clear: both;
    border-top: 1px solid #00000045;
    margin-top: 10px;
    margin-bottom: 10px;
}

#spm-popover-template .spm-keywords-input {
    border: 1px solid #cbcbcb;
    color: #000;
    float: right;
    box-shadow: none;
    width: 100%;
    margin-bottom: 0px;
    padding: 5px 20px;
    min-height: 50px;
    border-radius: 20px;
    font-size: 13px;
    background:white;
}

#spm-popover-template .spm-keywords-input::placeholder {
   color: #878787;
}

#spm-popover-template .spm-keywords-input:focus {
    outline: none;
}


#spm-popover-template .spm-pop-body
{
    display: flex;
}



#spm-popover-template .spm-pop-body textarea {
    box-shadow: none;
    /* border: 1px solid #e3e1e1; */
    color: #5e5f64;
    min-height: 200px;
    background: #DEE8F5;
    border-radius: 25px;
    resize: none;
    padding: 15px;
    font-family: "Space Grotesk" !important;
    font-size: 16px;
    width:100%;
    border: none;
    margin-top: 20px;
}

#spm-popover-template .spm-pop-body textarea:focus {
    outline: none;
}

#spm-popover-template .spm-pop-body textarea::placeholder {
    color: #ccc;
}

#spm-popover-template .spm-pop-footer {
    display: flex;
    justify-content: center;
    gap: 25px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

#spm-popover-template .spm-popover-button {
    height: 39px;
    box-shadow: none;
    border: 1px solid #0050B2;
    transition: .3s all;
    color: #000;
    background: white;
    border-radius: 20px;
    padding: 5px 30px;
    font-size: 20px;
    text-transform: uppercase;
}

#spm-popover-template button.sp-btn-active {

    box-shadow: none;
    border: 1px solid #0050B2;
    transition: .3s all;
    color: white;
    background: #0050B2;
    border-radius: 20px;
    padding: 5px 30px;
    font-size: 20px;
}

#spm-popover-template button.sp-btn-disabled {

    opacity: 0.5;
    pointer-events: none;
}

#spm-popover-template .spm-popover-button:hover {
    background: #0050B2;
    color: white;
}

#spm-popover-template #spm-pop-current-mood {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

#spm-popover-template .spm-pop-mood-item {
    padding: 2px 2px 2px 6px;
    border-bottom: 1px solid #edeaea;
    cursor: pointer;
    transition: .3s all;
}

#spm-popover-template .spm-pop-mood-item:hover {
    background: #d7d7db
}

#spm-popover-template .spm-pop-mood-item img {
    width: 22px;
    vertical-align: middle;
}

#spm-popover-template .spm-pop-mood-item span {
    display: inline-block;
    font-size: 13px;
}

#spm-popover-template .social-row-selected {
    background: #d6e9ff;
}

#spm-popover-template .spm-mood-item-icon-column {
    float: left;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    padding: 5px 5px;
    transition: .1s all;
    display: flex;
    position:relative;
}

#spm-popover-template .spm-mood-item-icon-column img {
    height: 32px;
    margin: 0 auto;
}

#spm-popover-template .spm-mood-item-icon-column span {
    display: none;
}

#spm-popover-template .spm-mood-item-icon-column-active {
    background: #0050B2;
    color: white;
    border-radius: 20px;
    padding: 4.5px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

#spm-popover-template .spm-mood-item-icon-column-active img {
    float: left;
}

#spm-popover-template .spm-mood-item-icon-column-active span {
    display: inline-block;
    margin-left: 5px;
    /* padding-top: 3px; */
}

#spm-popover-template .spm-pop-keywords .spm-entered-keywords-container {
    margin-top: 10px;
    width: 100%;
}

#spm-popover-template .spm-pop-keywords .spm-entered-keywords-container .spm-entered-keyword-item {
    background: #DAE8F6;
    border-radius: 20px;
    color: #878787;
    font-size: 14px;
    padding: 10px 20px;
    margin-bottom: 5px;
    display: inline-block;
    margin-right: 5px;
    margin-top: 4px;
}

#spm-popover-template .spm-pop-keywords .spm-entered-keywords-container .spm-entered-keyword-item .spm-centered-keyword-item-cancel {
    display: inline-block;
    margin-left: 5px;
    font-size: 16px;
    cursor: pointer;
}

#spm-popover-template .spm-pop-keywords .spm-entered-keywords-container .spm-entered-keyword-item .spm-centered-keyword-item-cancel::before {
    content: '✕';
    font-size: 14px;
}


.dropdown {
    position: absolute;
    width: 220px;
    box-shadow: 1px 1px 4px 0px #9b9b9b;
    ;
    border-radius: var(--radius);
    margin-top: 0.3rem;
    background: white;

    visibility: hidden;
    opacity: 0;
    transform: translateY(0.5rem);
    transition: all 0.1s cubic-bezier(0.16, 1, 0.5, 1);
}

.dropdown a {
    display: flex;
    align-items: center;
    column-gap: var(--gap);
    padding: 0.8rem 1rem;
    text-decoration: none;
    color: black;
}

.dropdown a:hover {
    background-color: var(--color);
    color: white;
}

.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0rem);
    margin-top: 0;
}

.arrow {
    transform: rotate(180deg);
    transition: 0.2s ease;
}

/* new  */
#spm-popover-template .spm-pop-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}

#spm-popover-template .spm-style-list {
    display: flex;
}

#spm-popover-template .spm-style-text {
    padding-top: 5px;
}

#spm-popover-template .spm-style-item {
    position: absolute;
    background: #D9D9D9;
    color: #242424;
    border-radius: 20px;
    padding: 5px 15px;
    margin: 0;
    float: left;
    text-align: center;
    cursor: pointer;
    transition: .1s all;
}

#spm-popover-template .spm-style-item-long {
    margin-left: 60px;
}

#spm-popover-template .spm-style-item-active-short {
    padding: 5px 40px 5px 10px;
}

#spm-popover-template .spm-style-item-active {
    background: #0050B2;
    color: white;
    border-radius: 20px;
    border-radius: 15px;
}

#spm-popover-template .spm-pop-mood-list {
    padding: 10px 10px 0;
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}

#spm-popover-template {
    width: 500px;
}

/* Start Tips */
#spm-popover-template .spm-pop-tips {
    display: flex;
    align-items: center;
    background: #0050B2;
    color: #ffffff;
    margin: 8px 0;
    margin-top: 0;
    justify-items: center;
    height: max-content;
    width: 100%;
    text-align: left;
    font-size: 15px;
    position: absolute;
    left: 0;
}

#spm-popover-template #spm-pop-tips-button {
    background: #FFFFFF;
    color: #242424;
    border-radius: 35px;
    padding: 4px 6px;
    font-size: 9.5px !important;
}

#spm-popover-template #spm-pop-tips-item {}

#spm-popover-template #spm-pop-tips-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

/* #spm-pop-tips-buttons #spm-pop-update-level {
    border-radius: 12px;
    width: max-content;
    font-size: 10px;
    background-color: #404dcb;
    padding: 8px;
    color: #f9bf12;
    font-weight: 600;
} */

#spm-popover-template #testSpan {
    padding-left: 10px;
}

#spm-popover-template .spm-pop-tips-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 190px;
    text-align: center;
    position: relative;
}

#spm-popover-template .spm-pop-tips-controls span {
    font-size: 15px;
    font-weight: bold;
}


#spm-popover-template .spm-pop-tips-list {
    width: 375px;
    display: flex;
    height: max-content;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 10px; */
}

#spm-popover-template .spm-pop-tips-controls-content {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-left: 11px;
    margin-bottom: 5px;
    position: absolute;
    top: -43px;

}

#spm-popover-template .spm-pop-tips-controls-tip-icon {
    height: 30px;
}

#spm-popover-template .spm-pop-pause-controls-pause-icon {
    height: 30px;
}

/* End Tips */

/* Start Left Comments */
#spm-popover-template .spm-pop-left-comments {
    position: static;
    display: flex;
    justify-content: center;
    margin-top: 120px;
    background: #0050B2;
    color: #ffffff;
    width: 100%;
    border-top: 0.5px solid #fff;
    left: 0;
    font-size: 15px;
    padding: 5px 0;
    border-radius: 0px !important;
    /* border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; */
}

#spm-popover-template .spm-pop-left-comments-link {
    margin-left: 10px;
    color: #ffffff;
    text-decoration: underline;
    font-weight: 600;
}

#spm-popover-template .spm-pop-get-access {
    background: #0050B2;
    color: #fff;
    font-size: 20px;
    left: 0;
    margin-top: 1px !important;
    border-top: 0.5px solid #fff;
    text-align: center;
    position: static;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}
.wrapperContentPopUp {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: clip;
}


#spm-popover-template .spm-pop-get-access span {
    color: #000;
    text-transform: uppercase;
    background: white;
    padding: 3px 16px;
    border-radius: 40px;
    user-select: none;
    cursor: pointer;
}

#spm-popover-template .spm-pop-get-access span:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
}

/* Active/Clicked styles to indicate interaction */
#spm-popover-template .spm-pop-get-access span:active {
    background-color: #d0d0d0;
    border-color: #aaa;
}

/* End Left Comments */

#spm-pop-copy-comment,
#spm-pop-write-comment {
    width: 167px;
}

#spm-pop-copy-comment span,
#spm-pop-write-comment span {
    font-size: 18px;
}

#spm-icon-write,
#spm-icon-copy {
    height: 20px;
    padding: 1px;
    margin: -3px;
    margin-right: 5px;
}

#spm-icon-pause {
    height: 10px;
    padding: 1px;
}

.banner-card {
    padding: 1.2rem 1.6rem;
    font-size: 13px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    box-shadow: 0 0 0 0.2rem #1e83e1;
    background: white;
    border-radius: 8px;
    margin: 9px 0;
}

.banner-card-content {
    display: flex;
    justify-content: space-between
}

.banner-hide-btn {
    cursor: pointer;
    margin-top: -5px;
}

.banner-hide-btn:before {
    content: "\2715";
    font-size: 18px;
}


/* #myModal {
    display: block;
    position: fixed;
    z-index:99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
} */

/* .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    text-align: center;
    position: relative; 
} */

/* .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
} */


.modal-cm {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10001;
    height: 100vh;
    position: fixed;
    color: #fff;
    background-color: rgba(0, 0, 0, .9);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
}

.modal-container {
    position: fixed;
    top: 150px;
    left: 50%;
    width: 615px;
    height: 738px;
    z-index: 1000;
    background-image: url('chrome-extension://__MSG_@@extension_id__/img/modal-back.png');
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, 0);
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    color: #fff;
}

.modal-container-achievement {
    position: fixed;
    top: 150px;
    left: 50%;
    width: 615px;
    height: 738px;
    z-index: 100;
    background-image: url('chrome-extension://__MSG_@@extension_id__/img/modal-back-new.png');
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, 0);
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-weight: 200;
    color: #fff;
}

.modal-header {
    background-image: url('chrome-extension://__MSG_@@extension_id__/img/modal-ribbon.png');
    width: 440px;
    margin: -50px auto auto;
    padding: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.modal-header-new {
    background-image: url('chrome-extension://__MSG_@@extension_id__/img/modal-ribbon.png');
    width: 440px;
    margin: -50px auto auto;
    padding: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.modal-title {
    text-align: center;
    margin-top: 0;
    font-size: 34px;
    font-weight: bold;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-block-start: 0.43em;
    margin-block-end: 0.83em;
}

.modal-body {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
}

.modal-caption {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.emotion {
    width: 70px;
    height: 70px;
}

.modal-text {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
}

.modal-sub-header {
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 10px;
}

.modal-sub-header>p {
    display: inline-block;
    margin-left: 10px;
    background: rgb(0, 80, 178);
    color: #fff;
    padding: 10px 40px;
    border-radius: 40px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}

.modal-content {
    width: 100%;
    margin-left: 80px;
    background-image: url('chrome-extension://__MSG_@@extension_id__/img/modal-condition.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px 20px 25px;
    margin-top: 10px;
}

.modal-content>p {
    padding-left: 50px;
    color: #fff;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.modal-content2 {
    width: 100%;
    margin-left: 100px;
    background-image: url('chrome-extension://__MSG_@@extension_id__/img/modal-condition.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px 20px 25px;
    margin-top: 10px;
    font-size: 18px;
}

.content-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.content-item {
    display: flex;
    gap: 5px;
    align-items: center;
}

.modal-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer-text {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
}

.subscribe-button {
    text-align: center;
}

.subscribe-button button {
    width: 340px;
    padding: 10px 0;
    border-radius: 40px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 23px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    border: none;
    box-shadow: 0px 4px 18px 0px #00000040;
    background: #FFFFFF;
    color: #000000;
    cursor: pointer;
}

.subscribe-button button.loading {
    background-color: #000;
}

/* Add media queries and additional styling as needed */

#close {
    overflow: hidden;
    left: 40px;
    position: relative;
    border: none;
    padding: 0;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: transparent;
    color: white;
    font: inherit;
    text-indent: 100%;
    cursor: pointer;
}

#close:focus {
    outline: solid 0 transparent;
    box-shadow: 0 0 0 2px #8ed0f9;
}

#close:hover {
    background: rgba(29, 161, 142, 0.1);
}

#close:before,
#close:after {
    position: absolute;
    top: 15%;
    left: calc(50% - .0625em);
    width: 0.125em;
    height: 70%;
    border-radius: 0.125em;
    transform: rotate(45deg);
    background: currentcolor;
    content: "";
}

#close:after {
    transform: rotate(-45deg);
}


.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
    margin: 2px 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

input:checked+.icon::before {
    font-family: "FontAwesome";
    content: "\f00c";
    transform: rotate(45deg);
    padding-top: 5px;
}

input:checked+.slider.w-color {
    background-color: #D9E5F4;
}

input:checked+.slider.icon {
    background-color: #0050B2;
}

input:checked+.slider {
    background-color: #2196f3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D9E5F4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}




.slider.w-color:before {
    background-color: #0050B2;
}

.slider.w-color.hover-active::before {
    background-color: #9aa1a978;
}

.slider.w-color {
    background-color: #D9E5F4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}



.slider.icon:before {
    background-color: #ffe05d;
}

.slider.icon:after {
    background-color: #e2df23;
}



/* Rounded sliders */
.slider.roundNew {
    border-radius: 34px;
}

.slider.roundNew:before {
    border-radius: 50%;
}

/* #example {
    border-radius: 34px !important;
    border: transparent !important;
    background-color: #B7D3EE !important;
} */

/* .hex-select select {
    border-radius: 34px !important;
    outline: none !important;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset;
    border-image: none !important;
    background-color: #B7D3EE !important;
}

.hex-select select:hover {
    border-radius: 34px !important;
    outline: none !important;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset;
    border-image: none !important;
    background-color: #B7D3EE !important;
} */

.dropdownEmotion {
    position: relative;
    z-index: 10;
    font-size: 12px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;

    & span {
        width: 140px;
        border-top: 1px solid #dddad6;
        border-left: 1px solid #dddad6;
        border-right: 1px solid #dddad6;
        border-bottom: 1px solid #dddad6;
        display: block;
        position: relative;
        z-index: 9;
        border-radius: 34px;
        box-shadow: none;
        padding: 0.5em;
        background: aliceblue;
        font-weight: 600;
        text-transform: uppercase;
        padding-left: 10px;
        color:black;
        box-sizing: border-box;

        & img {
            position: absolute;
            right: 5px;
            bottom: 11px;
        }
    }

    & ul {
        max-height: 0px;
        overflow: hidden;
        background: white;
        border-left: 1px solid #dddad6;
        border-right: 1px solid #dddad6;
        box-sizing: border-box;
        list-style: none;
        margin: 0;
        top: 10px;
        padding: 0;
        border-radius: 0px 0px 10px 10px;
        width: 140px !important;
        position: absolute;
        z-index: 8;
        box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.1);
        padding-top: 8px;


        & li a {
            color: inherit;
            display: block;
            text-decoration: none;
            padding: 0.5em 0.75em;

            &:hover {
                background-color: aliceblue!important;
            }
        }
    }

    &:hover ul {
        display: block;
        max-height: 350px;
        overflow-y: auto;
        transition: max-height 0.2s ease-in;
        border-bottom: 1px solid #dddad6;
    }

    & .class-name:after {
        content: " ";
        display: block;
        border-bottom: 0.5px solid #cbcbcb;
        width: 98%;
        /* Center the border */
    }
}

.notification-row {
    position: absolute;
    right: 0;
    top: 50px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2);
    width: 300px;
    background-color: white;
    border-radius: 10px;
    z-index: 999;
    max-height: 400px;
    overflow-y: auto;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;

    &:before {
        content: "";
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        background: #fff;
        position: absolute;
        right: 19%;
        top: -4px;
    }
}

.notification-row p
{
    margin: 0;
}

.notification-row>:first-child {
    border-radius: 10px 10px 0 0;
}

.notification-row>:last-child {
    border-radius: 0 0 10px 10px;
}

.notification-row>*:not(:last-child) {
    border-bottom: 0.5px solid rgba(0, 0, 0, .15);
}

.notification-row .noti-left {
    flex: 2 1;
    text-align: left;

}

.noti-left .person-type {
    color: var(--black, #12100B);
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.notification-row .noti-left .noti-info {
    display: flex;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.notification-row .noti-left .noti-info img {
    margin-left: 5px;
}

.notification-row .noti-right {
    flex: 3 1;
    width: 100%;
    text-align: left;
}

.notification-row .noti-right p {
    color: var(--black, #12100B);
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.comment-bar-container {
    display: flex;
    gap: 20px;
}

img.spm-tool-btn {
    position: relative;
}

div.spm-relative-box {
    position: relative;
}

div.spm-tool-box {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
}

div.spm-comment-box {
    position: absolute;
    z-index: 10;
    top: 50px;
    width: 344px;
    padding-left: 15px;
    padding-right: 14px;
    padding-top: 8px;
    padding-bottom: 12px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
}

/* Critical styles to keep */
.update-l-modal {
    position: fixed;
    z-index: 3000;
    display: flex;
    gap: 70px;
    top: 40px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    flex-direction: row;
    background: transparent;
    box-shadow: none;
}

.update-l-body {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 10px;
    max-height: 450px;
    overflow-y: auto;
}

.update-l-row {
    display: flex;
    gap: 8px;
    align-items: center;
}

.update-l-text {
    padding: 0;
    margin: 0;
    color: black;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, 'Open Sans', sans-serif;
    font-size: 14px;
}

.update-l-f-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
}

/* .update-l-f-text del
{
    color:red;
} */

/* .update-l-f-text span
{
    color:darkgreen;
} */

.update-l-f-btn {
    border: none;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: red;
}

.update-l-f-btn:hover {
    text-decoration: none;
}

/* New styles for stunning appearance */
.modal-cm {
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.update-l-modal {
    background: transparent;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.update-l-close {
    position: absolute;
    top: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
    color: #fff;
    font-weight: bold;
    right: -30px;
}

.update-l-close:hover {
    color: #007bff;
}

.update-l-starter,
.update-l-pre,
.update-l-pro {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.update-l-starter:hover,
.update-l-pre:hover,
.update-l-pro:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

.update-l-starter-header,
.update-l-pre-header,
.update-l-pro-header {
    height: 85px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-weight: bold;
}

.update-l-starter-header {
    background: linear-gradient(to right, rgba(208, 229, 255, 1), rgba(191, 211, 236, 1), rgba(222, 222, 222, 1), rgba(220, 236, 255, 1));
    color: rgba(75, 75, 75, 1);
}

.update-l-pre-header {
    background: radial-gradient(circle, #0050B2 0%, #2C281F 0.01%, #1F1B13 48.92%, #12100B 100%);
    color: white;
}

.update-l-pro-header {
    background: radial-gradient(circle, #0050B2 0%, #0A66C2 70.17%, #0050B2 100%);
    color: white;
}

.update-l-footer {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

.update-l-f-text {
    color: black;
}

.update-l-f-btn {
    background-color: red;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.update-l-f-btn:hover {
    background-color: #890000;
    transform: scale(1.05);
}

.update-l-f-btn:active {
    transform: scale(0.95);
}

.text-black {
    color: black;
}

.text-white {
    color: white;
}

/* Icons and additional styling */

.update-l-close::before {
    content: "\2715";
    font-size: 24px;
}

.update-l-starter-header {
    font-size: 28px;
    font-weight: bold;
}

.update-l-pre-header {
    font-size: 28px;
    font-weight: bold;
}

.update-l-pro-header {
    font-size: 28px;
    font-weight: bold;
}

/* Scroll bar styling */.dropdownEmotion ul::-webkit-scrollbar,
.saved-comment-list .comment-list::-webkit-scrollbar,
.saved-engagement-list::-webkit-scrollbar,
.update-l-body::-webkit-scrollbar {
    width: 8px!important;
}

.dropdownEmotion ul::-webkit-scrollbar-track,
.saved-comment-list .comment-list::-webkit-scrollbar-track,
.saved-engagement-list::-webkit-scrollbar-track,
.update-l-body::-webkit-scrollbar-track {
    background: rgba(200, 200, 200, 0.3)!important;
    /* Lighter and softer background */
}

.dropdownEmotion ul::-webkit-scrollbar-thumb,
.saved-comment-list .comment-list::-webkit-scrollbar-thumb,
.saved-engagement-list::-webkit-scrollbar-thumb,
.update-l-body::-webkit-scrollbar-thumb {
    background-color: rgba(150, 150, 150, 0.5)!important;
    /* Softer thumb color */
    border-radius: 10px!important;
    border: 2px solid rgba(200, 200, 200, 0.3)!important;
    /* Softer border */
}

.dropdownEmotion ul::-webkit-scrollbar-thumb:hover,
.saved-comment-list .comment-list::-webkit-scrollbar-thumb:hover,
.saved-engagement-list::-webkit-scrollbar-thumb:hover,
.update-l-body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(120, 120, 120, 0.7)!important;
    /* Slightly darker on hover, but still soft */
}

/* Engagements */
.no-engagement{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

/* Dissable dropdown */
.dropdownEmotion[disabled] {
    pointer-events: none;
    opacity: 0.5;
}

.shepherd-highlight {
    position: relative !important;
    z-index: 9999 !important;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5) !important;
    border: 2px solid #0050B2 !important;
    border-radius: 4px !important;
}

.shepherd-element {
    z-index: 10000 !important;
}

.shepherd-modal-overlay-container {
    z-index: 9998 !important;
}

.shepherd-text {
    font-size: 16px!important;
    padding: 0  10px 10px 10px!important;
}

.shepherd-content {
    padding: 0!important;
}

.shepherd-header
{
    padding-top: 0!important;
}

.shepherd-footer {
    padding: 0 10px 10px!important;
}

.shepherd-arrow:before {
    border: 1px solid rgb(0, 80, 178);
    background: white;
}

/* Adjust arrow border based on placement */
.shepherd-element[data-popper-placement^='top'] .shepherd-arrow:before {
    border-top: none;
    border-left: none;
}

.shepherd-element[data-popper-placement^='bottom'] .shepherd-arrow:before {
    border-bottom: none;
    border-right: none;
}

.shepherd-element[data-popper-placement^='left'] .shepherd-arrow:before {
    border-right: none;
    border-bottom: none;
}

.shepherd-element[data-popper-placement^='right'] .shepherd-arrow:before {
    border-left: none;
    border-top: none;
}

.no-pulse-animation .shepherd-highlight {
    animation: none !important;
}

.shepherd-highlight-disabled
{
    animation: none !important;
}


.shepherd-content {
    border: 1px solid rgb(0, 80, 178);
}

.spm-pop-tips
{
    display: flex!important;
}

.spm-pop-left-comments
{
    margin-top: 120px!important;
}

.spm-pop-get-access
{
    margin-top: 153px!important;
}

.hide-academy-tips .spm-pop-tips {
    display: none !important;
}

.hide-academy-tips .spm-pop-left-comments {
    margin-top: 0 !important;
}

.hide-academy-tips .spm-pop-get-access
{
    margin-top: 33px !important;
}

.ql-editor p:not(:first-child) {
    margin-bottom: 10px!important;
}

.spm-mood-item-icon-column[data-mood="custom"].pulse-animation {
    position: relative;
}

.spm-mood-item-icon-column[data-mood="custom"].pulse-animation::before {
    content: '';
    position: absolute;
    top: 45%;
    left: 45%;
    width: 20px;
    /* Even smaller initial size */
    height: 20px;
    border-radius: 40px;
    z-index: -1;
    transform: translate(-50%, -50%);
    animation: customMoodPulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes customMoodPulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        box-shadow: 0 0 0 0 rgba(0, 80, 178, 0.7);
        /* Increased opacity */
        width: 20px;
        height: 20px;
        opacity: 0.7;
        /* Stronger initial opacity */
    }

    70% {
        transform: translate(-50%, -50%) scale(1);
        box-shadow: 0 0 30px 15px rgba(0, 80, 178, 0.3);
        /* Larger shadow, maintains some opacity */
        width: 150%;
        /* Expand beyond the block */
        height: 150%;
        opacity: 0.3;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        box-shadow: 0 0 40px 20px rgba(0, 80, 178, 0);
        /* Even larger final shadow */
        width: 180%;
        /* Even larger final size */
        height: 180%;
        opacity: 0;
    }
}

#spm-pop-generated-comment {
    overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: rgb(0, 80, 178) rgb(217, 229, 244);
        padding-right: 18px;
        /* Add padding to the right to accommodate the scrollbar */
    }
    
    /* For Webkit browsers (Chrome, Safari, etc.) */
    #spm-pop-generated-comment::-webkit-scrollbar {
        width: 8px;
        position: absolute;
        left: 10px;
    }
    
    #spm-pop-generated-comment::-webkit-scrollbar-track {
        background: rgb(217, 229, 244);
        border-radius: 10px;
        margin: 10px 0;
        /* Add some margin to ensure rounded corners are visible */
    }
    
    #spm-pop-generated-comment::-webkit-scrollbar-thumb {
        background: rgb(0, 80, 178);
        border-radius: 10px;
        border: 2px solid rgb(217, 229, 244);
        /* Add a border to ensure rounded corners */
    }
    
    #spm-pop-generated-comment::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 80, 178, 0.8);
    }
    
    /* Hide scrollbar buttons */
    #spm-pop-generated-comment::-webkit-scrollbar-button {
        display: none;
    }
    
    /* For Firefox */
    #spm-pop-generated-comment {
        scrollbar-width: thin;
        scrollbar-color: #0050b2 #d9e5f4;
    }
    
    /* Adjust the scrollbar position */
    #spm-pop-generated-comment {
        position: relative;
    }
    
    #spm-pop-generated-comment::before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        /* Move the scrollbar 10px to the left */
        bottom: 0;
        width: 8px;
        /* Width of the scrollbar */
        background: rgb(217, 229, 244);
        border-radius: 10px;
    }
    
    #spm-pop-generated-comment::-webkit-scrollbar {
        width: 8px;
        position: absolute;
        left: -10px;
        /* Move the scrollbar 10px to the left */
    }

    .PopoverWrapper {
        position: relative;
        display:block
    }
    span.PopoverWrapper:after {
        content: "";
        position: absolute;
        left: -15px;
        top: 140px;
        bottom: -10px;
        border-left-width: 0px;
        border-right-color: rgb(255 255 255);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 15px solid #ffffff;
        border-bottom: 10px solid transparent;
        filter: drop-shadow(-7px 8px 10px gray);
    }
    .influencer-badge-mood {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .influencer-badge-mood span {
        font-size: 8px;
        color: #e4bf2d;
        margin: 0px !important;
        line-height: 10px !important;
    }
    .influencer-badge-mood img {
        height: max-content !important;
    }
    .influencer-popup {
        position: absolute;
        background-color: rgba(0, 80, 178, 1);
        color: white;
        border-radius: 15px;
        padding: 20px;
        width: 315px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        font-family: Space Grotesk;
        font-size: 15px;
        z-index: 999;
        top: 45px;
        right: -200px;
        margin: auto;
        box-sizing: border-box;
    }
    .influencer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
    .influencer-text {
        text-align: center;
        padding: 10px 0;
        color: white;
    }
    .influencer-ul {
        padding-left: 20px;
        margin: 15px 0;
        text-align: center;
        color: white;
        list-style-type: none;
    }
    .influencer-li {
        margin-bottom: 5px;
    }
    .influencer-p {
        margin: 15px 0;
        font-size: 15px;
        text-align: center;
        color: white;
    }
    .influencer-button {
        background-color: white;
        color: #0066cc;
        border: none;
        padding: 12px 15px;
        border-radius: 25px;
        cursor: pointer;
        font-weight: bold;
        display: block;
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
        transition: background-color 0.3s ease;
        font-weight: 500;
    }
    .influencer-logo-title {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .influencer-logo {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
    .influencer-h2 {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        color: white;
        text-align: start;
    }
    .influencer-badge {
        color: #E0B200;
        border: 1px solid #E0B200;
        background-color: rgba(247, 236, 191, 0.2);
        padding: 2px 8px;
        border-radius: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 2px;
    }
    .influencer-badge img {
        width: 10px;
        height: 10px !important;
    }
    button.influencer-close {
        position: relative;
        border: 0px;
        background: none;
        color: #fff;
        display: inline-block;
    }

    .panel {
        border-radius: 4px;
        background: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
        position: absolute;
        right: 0;
        top: -110px;
        color: black;
        font-family: Space Grotesk;
        padding: 15px 28px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        max-width: 400px;
        width: 100%;
        background-color: #fff !important;
        z-index: 9;
    }
    .panel .header {
        display: flex !important;
        justify-content: space-between !important;
        border-bottom: 1px solid rgb(199 199 199);
        padding-bottom: 10px !important;
        height: max-content !important;
        padding-top: 10px !important;
        align-items: center;
        animation: none !important;
    }
    .header h2 {
        margin: 0px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: black;
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }
    button#close-settings-menu {
        height: 20px;
        width: 20px;
        background: none;
        border: 0px;
        padding: 0px !important;
        position: relative;
    }

    button#close-settings-menu svg {
        width: 100% !important;
        height: 100% !important;
        inset: auto !important;
        position: static !important;
    }

    .panel .toggle {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        margin: 0;
    }
   
    .feature {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
    }
    span.feature-name {
        display: flex;
        font-weight: 500;
        gap: 6px;
        align-items: center;
    }
    .info-icon {
        color: #999;
        margin-left: 5px;
        font-size: 13px;
        position: relative;
        cursor: pointer;
    }
    .info-icon::after {
        content: attr(data-tooltip);
        position: absolute;
        bottom: 120%;
        left: 50%;
        transform: translateX(-50%);
        background-color: black;
        color: white;
        padding: 6px 8px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease-in-out;
        z-index: 99;
      }
      
    .info-icon:hover::after {
        opacity: 1;
        visibility: visible;
    }
    .settings-menu .toggle input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .settings-menu .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }
    .settings-menu .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
    }
    body .spm-pop-get-access.getAccesWrapper {
        position: static;
        
    }
    body .spm-pop-left-comments.spm-popWrapper {
        position: static;
    }

@media screen and (min-width: 1024px) {
.update-l-modal {
        padding-right: 0;
        /* Remove padding on smaller screens */
    }

    .update-l-close {
        top: -30px;
        /* Move the close button above the modal on smaller screens */
        right: 10px;
    }
    
        .update-l-pro-header{
            text-align: center;
        }

    .update-l-starter,
    .update-l-pre,
    .update-l-pro {
        min-width: 30%;
        max-width: 300px;
    }

    .update-l-body {
        max-height: 350px;
    }
}

@media screen and (max-width: 1023px) {
    .update-l-modal {
        flex-direction: column;
        align-items: center;
        max-height: 90vh;
        overflow-y: auto;
    }

    .update-l-starter,
    .update-l-pre,
    .update-l-pro {
        width: 90%;
        max-width: 350px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .update-l-modal {
        flex-direction: row;
        justify-content: center;
        gap: 10px;
    }
    
        .update-l-close {
            top: 0;
            right: 10px;
        }

    .update-l-starter,
    .update-l-pre,
    .update-l-pro {
        width: 30%;
        max-width: 250px;
    }

    .update-l-starter-header,
    .update-l-pre-header,
    .update-l-pro-header {
        font-size: 20px;
    }

    .update-l-body {
        max-height: 300px;
    }

    .update-l-f-text {
        font-size: 16px;
    }

    .update-l-f-btn {
        font-size: 14px;
        padding: 8px 12px;
    }
}

@media screen and (max-width: 767px) {
    .update-l-modal {
        flex-direction: column;
        align-items: center;
        max-height: 90vh;
        overflow-y: auto;
        padding-right: 0;
    }

        .update-l-close {
            top: -30px;
            right: 0;
        }
    .update-l-starter,
    .update-l-pre,
    .update-l-pro {
        width: 90%;
        max-width: 300px;
    }

    .update-l-starter-header,
    .update-l-pre-header,
    .update-l-pro-header {
        font-size: 22px;
    }

    .update-l-f-text {
        font-size: 18px;
    }

    .update-l-f-btn {
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media screen and (max-width: 480px) {
    .update-l-modal {
        padding: 5px;
    }

    .update-l-starter,
    .update-l-pre,
    .update-l-pro {
        max-width: 280px;
    }

    .update-l-starter-header,
    .update-l-pre-header,
    .update-l-pro-header {
        font-size: 20px;
        height: 70px;
    }

    .update-l-f-text {
        font-size: 16px;
    }

    .update-l-f-btn {
        font-size: 12px;
        padding: 6px 12px;
    }
}