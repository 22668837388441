.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 17px 0px;
  background-repeat: repeat;
  background-position: bottom;
  background-position-y: center;
  background-image: url('../../../assets/WhiteBakgroundDust.jpg');
  height: calc(100% - 34px);
  overflow: auto;
  background-size: cover;
}

.commenter-big-logo {
  width: 220px;
  height: 80px;
}

.onboarding-body-container {
  max-width: 820px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
 
  /* transform: scale(0.75);
  transform-origin: top center; */
}

.onboarding-body {
  max-width: 1170px;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 10px 10px 0px 0px #5BB7E7;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 65px;
}
.step0-onboarding-heading {
  font-family: var(--roboto-font);
  font-weight: 500;
  font-size: 35px;
  line-height: normal;
  letter-spacing: -3;
  text-align: center;
  text-transform: capitalize;
}

.step0-onboarding-subheading {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 0;
  text-align: center;
}

.step0-onboarding-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 35px;
  width: 100%;
  box-sizing: border-box;
}

.onboarding-body-global {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 50px;
 
  width: 100%;
  box-sizing: border-box;
}

.onboarding-below-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
 
  gap: 15px;
}

.commenter-logo-text {
  font-family: Roboto Slab;
  font-weight: 500;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: -3%;
  text-align: center;
  text-transform: capitalize;
  color: #0450AC;
}

.error-message-onboarding {
  font-family: Roboto Slab;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #FF0000;
}

.info-message-onboarding {
  font-family: Roboto Slab;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #666666;
}

.full-screen {
 height: 100dvh;

}

body.showfullscreen footer , body.showfullscreen header{
  display: none !important;
}
.stepmodalContainer>div {
  max-width: 100%;
  width: 100%;
}



@media only screen and (max-width:767px){
  .step0-onboarding-heading {
    font-size: 28px !important;
    line-height: 38px;
  }
  .onboarding-body-global {
    gap: 30px;
  }
  .step0-onboarding-subheading {
    font-size: 22px;
  }
  .commenter-big-logo {
    width: 240px;
    height: 80px;
  }
  .step0-onboarding-cards {
    width: 100% !important;
  }
  .onboarding-body {
    box-shadow: 5px 5px 0px 0px #5BB7E7;
  }
  .onboarding-Wrapper-container {
    width: 100% !important;
  }
  .onboarding-body-global {
   margin-top: 50px;
  }
  .onboarding-container {
    height: calc(100% - 36px);
    padding: 25px;
   }
   .onboarding-body-container {
   
    transform: unset;
    transform-origin: top center;
  }
  body.showfullscreen .mobile{
    display: none !important;
    }

  .onboarding-body{
    padding: 20px 15px;
  }
  .commenter-logo-text{
    font-size: 35px;
    line-height: normal;
  }
  .step0-onboarding-heading.laststep {
    text-align: start;
  }
  .switchlast {
    top: 6px !important;
  }
 
  
  
}