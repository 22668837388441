
button#getPostingTime {
    background-image: linear-gradient(0deg, rgb(12, 35, 134) 0%, rgb(37, 59, 152) 13%, rgb(0, 142, 212) 100%);
    color: rgb(255, 255, 255);
    border: 0px;
    border-radius: 8px;
    padding: 17px 15px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 21%;
    height: 50px;
    line-height: normal;
}
  button#getPostingTime.animation:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: transparent;
    animation: spin 1s ease-in-out infinite;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
   
}
  button#getPostingTime.animation span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 50%;
    transition: top .5s ease-out;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg)
    }
  }