.top-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 22px;
    padding-bottom: 14px;
    gap: 12px;
    background-color: rgb(0, 80, 178);
}

.top-title {
    color: white;
    text-decoration: underline;
    margin: 0;
}

.navigation-link.login {
    background-color: rgb(0, 80, 178);
    outline: 3px solid rgba(0, 80, 178, 0.33);
    border-radius: 30px;
    padding: 10px 20px;
    color: #fff;
    transition: .4s;
}

.navigation-link.login:hover{
    background-color: #fff;
    color: rgba(0, 80, 178);
    outline: 3px solid rgba(0, 80, 178, 1);
    transition: .4s;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .navigation-link.login {
        position: relative;
        outline: none;
    }

    .navigation-link.login::before{
        content: "";
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        border-radius: 30px;
        border: 3px solid rgba(0, 80, 178, 0.33);
    }
}

.platform-pages nav > a.active > .login {
    color: #fff;
}

@media only screen and (min-width: 768px) {
    .top-bar {
        flex-direction: row;
        gap: 40px;
    }
}

@media only screen and (min-width: 1024px) {
    .top-bar > div > a {
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
